<template>
    <div class="flex flex-1 overflow-y-auto">
        <div class="flex-1 max-w-7xl sm:px-6 lg:px-8">
            <div class="container px-1 mx-auto space-y-8">
                <form-block
                    class="my-8"
                    title="Details for Query Results Acquisition at Real-time"
                    description="Details how to get access to the selected topic"
                >
                    <div class="flex items-center flex-auto">
                        <div class="flex-initial w-32 mr-3 text-sm text-neutral-700">
                            <span>Connection URL</span>
                        </div>
                        <div class="flex-auto">{{ kafkaBrokers }}</div>
                        <CopyToClipboardButton name="url" tooltipTitle="connection URL(s)" :value="kafkaBrokers" />
                    </div>
                    <div class="flex items-center flex-auto">
                        <div class="flex-initial w-32 mr-3 text-sm text-neutral-700">
                            <span>Topic</span>
                        </div>
                        <div class="flex-auto">{{ configuration.kafkaConnectionDetails.topic }}</div>
                        <CopyToClipboardButton name="topic" :value="configuration.kafkaConnectionDetails.topic" />
                    </div>
                    <div class="flex items-center flex-auto">
                        <div class="flex-initial w-32 mr-3 text-sm text-neutral-700">
                            <span>Group</span>
                        </div>
                        <div class="flex-auto">{{ configuration.kafkaConnectionDetails.group }}</div>
                        <CopyToClipboardButton name="group" :value="configuration.kafkaConnectionDetails.group" />
                    </div>
                    <div class="flex items-center flex-auto">
                        <div class="flex-initial w-32 mr-3 text-sm text-neutral-700">
                            <span>Security Protocol</span>
                        </div>
                        <div class="flex-auto">{{ configuration.kafkaConnectionDetails.securityProtocol }}</div>
                        <CopyToClipboardButton
                            name="securityProtocol"
                            tooltipTitle="security protocol"
                            :value="configuration.kafkaConnectionDetails.securityProtocol"
                        />
                    </div>
                    <div class="flex items-center flex-auto">
                        <div class="flex-initial w-32 mr-3 text-sm text-neutral-700">
                            <span>SASL mechanism</span>
                        </div>
                        <div class="flex-auto">{{ configuration.kafkaConnectionDetails.saslMechanism }}</div>
                        <CopyToClipboardButton
                            name="saslMechanism"
                            tooltipTitle="SASL mechanism"
                            :value="configuration.kafkaConnectionDetails.saslMechanism"
                        />
                    </div>
                    <div class="flex items-center flex-auto">
                        <div class="flex-initial w-32 mr-3 text-sm text-neutral-700">
                            <span>Username</span>
                        </div>
                        <div class="flex-auto">{{ configuration.kafkaConnectionDetails.username }}</div>
                        <CopyToClipboardButton name="username" :value="configuration.kafkaConnectionDetails.username" />
                    </div>
                    <div class="flex flex-wrap items-center flex-auto">
                        <div class="flex-initial w-32 mr-3 text-sm text-neutral-700">
                            <span>Password</span>
                        </div>
                        <template v-if="kafkaPassword">
                            <div class="flex-auto">{{ kafkaPassword }}</div>
                            <CopyToClipboardButton name="password" :value="kafkaPassword" />
                        </template>
                        <template v-else>
                            <div class="flex-auto text-sm italic text-neutral-600">
                                The password cannot be displayed
                            </div>
                            <button
                                v-if="configuration.kafkaConnectionDetails.stepId"
                                type="button"
                                class="inline-flex items-center py-1 pl-2 pr-3 text-xs font-medium leading-5 text-white uppercase transition duration-150 ease-in-out border rounded border-neutral-600 hover:border-neutral-500 bg-neutral-600 hover:bg-neutral-500 focus:outline-none focus:ring-neutral focus:border-neutral-700 active:bg-neutral-700 disabled:bg-neutral-400"
                                :disabled="resettingPassword"
                                @click="confirmResetPassword"
                            >
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    class="w-4 h-4 mr-1"
                                >
                                    <path
                                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                    ></path>
                                </svg>
                                {{ resetButton }}
                            </button>
                        </template>
                    </div>
                    <alert-banner
                        v-if="kafkaPassword && showPasswordBanner"
                        type="warn"
                        closable
                        @close="showPasswordBanner = false"
                    >
                        <template v-slot:description
                            >Please make sure to save this password because it will not be shown again.
                        </template>
                    </alert-banner>
                </form-block>

                <portal to="modals">
                    <confirm-modal
                        v-if="showResetPasswordModal"
                        color="yellow"
                        title="Reset Password"
                        message="Are you sure you want to reset your Kafka password?"
                        okButtonText="Yes, I am sure"
                        @cancel="showResetPasswordModal = false"
                        @ok="resetPassword"
                    />
                </portal>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useAxios } from '@/app/composable';
import { ConfirmModal, AlertBanner, FormBlock, CopyToClipboardButton } from '@/app/components';
import { KafkaAPI } from '../../../data-checkin/api';

export default defineComponent({
    name: 'StreamingQueryResults',
    components: {
        ConfirmModal,
        AlertBanner,
        FormBlock,
        CopyToClipboardButton,
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
    },
    setup(props, { root }) {
        const { exec } = useAxios(true);
        const showResetPasswordModal = ref(false);
        const resettingPassword = ref(false);
        const kafkaPassword = ref(props.configuration.kafkaConnectionDetails.password);
        const showPasswordBanner = ref(true);
        props.configuration.kafkaConnectionDetails.password = null; // eslint-disable-line no-param-reassign

        const kafkaBrokers = computed(() => {
            let brokers = props.configuration.kafkaConnectionDetails.url.split(',').map((broker) => broker.trim());
            brokers = brokers.join(', ');
            return brokers;
        });

        const resetButton = computed(() => {
            if (resettingPassword.value) return 'Resetting Password...';
            return 'Reset Password';
        });

        const confirmResetPassword = () => {
            showResetPasswordModal.value = true;
        };

        const resetPassword = async () => {
            showResetPasswordModal.value = false;
            resettingPassword.value = true;
            exec(
                KafkaAPI.resetPassword(
                    props.configuration.kafkaConnectionDetails.username,
                    props.configuration.kafkaConnectionDetails.stepId,
                    undefined,
                ),
            )
                .then((res) => {
                    if (res) kafkaPassword.value = res.data.password; // eslint-disable-line no-param-reassign
                    resettingPassword.value = false;
                    root.$toastr.s('Kafka password has been reset!', 'Success');
                })
                .catch(() => {
                    root.$toastr.e('Failed to reset kafka password', 'Failed');
                    resettingPassword.value = false;
                });
        };

        return {
            kafkaBrokers,
            kafkaPassword,
            confirmResetPassword,
            resetButton,
            resetPassword,
            resettingPassword,
            showResetPasswordModal,
            showPasswordBanner,
        };
    },
});
</script>

<style></style>

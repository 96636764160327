














































































































































































































































import 'prismjs';
import 'prismjs/components/prism-json';
import { defineComponent, computed, ref, reactive, watch } from '@vue/composition-api';
import * as R from 'ramda';
import {
    TwAccordion,
    TwAccordionCard,
    AlertBanner,
    DataModelTree,
    ShortTypeBadge,
    TwButton,
    SvgImage,
} from '@/app/components';
import { QueryParameterTypes } from '../../constants';
import { SearchUtils } from '../../utils';
import { LightningBoltIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'ConfigureDatasetQuery',
    components: {
        TwButton,
        TwAccordion,
        TwAccordionCard,
        AlertBanner,
        DataModelTree,
        ShortTypeBadge,
        SvgImage,
        LightningBoltIcon,
    },
    model: {
        prop: 'configuration',
        event: 'changeConfiguration',
    },
    props: {
        datasets: {
            type: Object,
            required: true,
        },
        configuration: {
            type: Object,
            required: true,
        },
        similarDatasets: {
            type: Boolean,
            default: false,
        },
        isSaved: {
            type: Boolean,
            required: true,
        },
        id: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const binaryConceptSelected = ref<boolean>(props.configuration.binaryConceptSelected);
        const showSimilarDatasetsBanner = ref<boolean>(true);
        const queryParameterOrder = ref<string[]>(Object.keys(props.configuration.params));

        const consolidatedSelectedFields = reactive(
            props.configuration.datasets.reduce((accumulator: any, dataset: { id: number; fields: string[] }) => {
                return [...new Set([...accumulator, ...dataset.fields])];
            }, []),
        );

        const queryParameterTypes: { datetime: any; integer: any; string: any; double: any; boolean: any } = {
            datetime: [QueryParameterTypes.Value, QueryParameterTypes.Range],
            integer: [QueryParameterTypes.Value, QueryParameterTypes.Range],
            double: [QueryParameterTypes.Value, QueryParameterTypes.Range],
            boolean: [QueryParameterTypes.Value],
            string: [QueryParameterTypes.Value],
        };

        const concepts = reactive(SearchUtils.flattenConcepts(props.datasets, props.configuration));

        Object.keys(concepts).forEach((key: string) => {
            Object.keys(concepts[key]).forEach((field: string) => {
                if (consolidatedSelectedFields.includes(field)) concepts[key][field].selected = true;
            });
        });

        const selectedDatasetsById = reactive(
            props.configuration.datasets.reduce((accumulator: any, dataset: any) => {
                return {
                    ...accumulator,
                    [dataset.id]: dataset,
                };
            }, {}),
        );

        const atLeastOneFieldSelected = computed(() => {
            const foundSelected = [];
            for (let c = 0; c < Object.keys(concepts).length; c++) {
                const conceptKey = Object.keys(concepts)[c];
                const concept = concepts[conceptKey];
                for (let sc = 0; sc < Object.keys(concept).length; sc++) {
                    const subConceptKey = Object.keys(concept)[sc];
                    const subConcept = concept[subConceptKey];
                    if (subConcept.selected) {
                        foundSelected.push(conceptKey);
                        break;
                    }
                }
            }
            return foundSelected.length === Object.keys(concepts).length;
        });

        const dataSetsById = computed(() => {
            return Object.values(props.datasets).reduce((accumulator: any, dataset: any) => {
                return {
                    ...accumulator,
                    [dataset.id]: dataset,
                };
            }, {});
        });

        const fields: any = computed(() => {
            return Object.values(concepts).reduce((accumulator: any, concept: any) => {
                return {
                    ...accumulator,
                    ...concept,
                };
            }, {});
        });

        const updatedResult = computed(() => {
            const result: any = {
                params: {},
                datasets: [],
                download: 'direct',
                kafkaConnectionDetails: {},
                binaryConceptSelected: binaryConceptSelected.value,
            };
            for (let c = 0; c < Object.keys(concepts).length; c++) {
                const domainId = Object.keys(concepts)[c];
                const domain = concepts[domainId];
                const datasetObject: any = {
                    id: domainId,
                    fields: [],
                    selectedAction: selectedDatasetsById[domainId].selectedAction,
                };
                queryParameterOrder.value.forEach((conceptKey: string) => {
                    if (conceptKey in domain && domain[conceptKey].queryParameter) {
                        const concept = domain[conceptKey];
                        result.params[conceptKey] = {
                            filterType: concept.queryParameterType,
                            fieldType: concept.type,
                        };
                    }
                });
                Object.keys(domain).forEach((conceptKey: string) => {
                    const concept = domain[conceptKey];

                    if (concept.selected) {
                        datasetObject.fields.push(conceptKey);
                    }
                });
                result.datasets.push(datasetObject);
            }
            return result;
        });

        watch(
            () => updatedResult.value,
            (res) => {
                emit('changeConfiguration', res);
            },
        );

        const foundDatasets = computed(() => Object.keys(props.datasets).length > 0);

        // Calculates which query parameters are available to be added
        const availableQueryParameters = computed(() => {
            const result: any[] = [];
            Object.keys(fields.value).forEach((conceptKey: string) => {
                const concept = fields.value[conceptKey];
                if (
                    concept.indexed &&
                    concept.selected &&
                    !concept.queryParameter &&
                    concept.type &&
                    concept.type !== 'base64binary'
                ) {
                    result.push(conceptKey);
                }
            });

            return result;
        });

        // Calculates which query parameters are selected
        const selectedQueryParameters = computed(() => {
            const result: string[] = [];
            Object.keys(concepts).forEach((domainId) => {
                const domain = concepts[domainId];
                Object.keys(domain).forEach((conceptKey) => {
                    const concept = domain[conceptKey];
                    if (concept.queryParameter && concept.selected && result.indexOf(conceptKey) === -1) {
                        result.push(conceptKey);
                    }
                });
            });

            return result;
        });

        const removeQueryParameter = (keyToRemove: string) => {
            Object.keys(concepts).forEach((domainId) => {
                const domain = concepts[domainId];
                if (keyToRemove in domain) {
                    domain[keyToRemove].queryParameter = false;
                    domain[keyToRemove].queryParameterType = QueryParameterTypes.Value;
                }
            });
            if (queryParameterOrder.value.includes(keyToRemove)) {
                queryParameterOrder.value.splice(queryParameterOrder.value.indexOf(keyToRemove), 1);
            }
        };

        // Events
        const selectionChanged = (selectedItems: any) => {
            binaryConceptSelected.value = false;
            Object.keys(concepts).forEach((domainId) => {
                const domain = concepts[domainId];
                Object.keys(domain).forEach((conceptKey) => {
                    const concept = domain[conceptKey];
                    if (R.is(Object, concept)) {
                        if (selectedItems.indexOf(conceptKey) > -1) {
                            if (!consolidatedSelectedFields.includes(conceptKey)) {
                                consolidatedSelectedFields.push(conceptKey);
                            }
                            concept.selected = true;
                            if (concept.type === 'base64binary') {
                                binaryConceptSelected.value = true;
                            }
                        } else {
                            // reset to defaults of unselected
                            concept.selected = false;
                            if (consolidatedSelectedFields.includes(conceptKey)) {
                                consolidatedSelectedFields.splice(consolidatedSelectedFields.indexOf(conceptKey), 1);
                            }
                            removeQueryParameter(conceptKey);
                        }
                    }
                });
            });
        };

        const addQueryParam = () => {
            let selectedParam = null;

            if (availableQueryParameters.value.length > 0) {
                for (let c = 0; c < Object.keys(concepts).length; c++) {
                    const domainId = Object.keys(concepts)[c];
                    if (!selectedParam && availableQueryParameters.value[0] in concepts[domainId]) {
                        [selectedParam] = availableQueryParameters.value;
                    }
                    if (selectedParam in concepts[domainId]) {
                        concepts[domainId][selectedParam].queryParameter = true;
                        if (!queryParameterOrder.value.includes(selectedParam)) {
                            queryParameterOrder.value.push(selectedParam);
                        }
                    }
                }
            }
        };

        const queryParameterChange = (event: any) => {
            const changedKey = event.target.name;
            const newSelection = event.target.value;
            Object.keys(concepts).forEach((domainId) => {
                const domain = concepts[domainId];
                if (changedKey in domain) {
                    domain[changedKey].queryParameter = false;
                }
                if (newSelection in domain) {
                    domain[newSelection].queryParameter = true;
                }
            });
            queryParameterOrder.value.splice(queryParameterOrder.value.indexOf(changedKey), 1, newSelection);
        };

        const queryParameterTypeChange = (event: any) => {
            const changedKey = event.target.name;
            const typeSelected = event.target.value;
            Object.keys(concepts).forEach((domainId) => {
                const domain = concepts[domainId];
                if (changedKey in domain) {
                    domain[changedKey].queryParameterType = typeSelected;
                }
            });
        };

        return {
            queryParameterOrder,
            foundDatasets,
            showSimilarDatasetsBanner,
            consolidatedSelectedFields,
            concepts,
            availableQueryParameters,
            selectedQueryParameters,
            queryParameterTypes,
            dataSetsById,
            fields,
            selectionChanged,
            addQueryParam,
            queryParameterChange,
            queryParameterTypeChange,
            removeQueryParameter,
            emit,
            atLeastOneFieldSelected,
        };
    },
});















































































































































































































































































































































































































































































































































































































































































































import {
    ConfirmModal,
    DataModelTree,
    Scrollbar,
    SvgImage,
    Toggle,
    TwAccordion,
    TwAccordionCard,
    TwButton,
} from '@/app/components';
import { S } from '@/app/utilities';
import { AssetTypeId } from '@/modules/asset/constants';
import { ModelAPI, SearchAPI } from '@/modules/search/api';
import { useAxios } from '@/app/composable';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import AdvancedQueryBuilder from '../components/AdvancedQueryBuilder.vue';
import CombineDatasets from '../components/CombineDatasets.vue';
import DataQueryRetrievalPreview from '../components/DataQueryRetrievalPreview.vue';
import Facet from '../components/Facet.vue';
import QuerySummary from '../components/QuerySummary.vue';
import SearchResult from '../components/SearchResult.vue';
import SearchResultFooter from '../components/SearchResultFooter.vue';
import { useModel } from '../composable';
import { getFacets } from '../config/facet';
import { SortingOption } from '../constants';
import { SearchUtils } from '../utils';

export default defineComponent({
    name: 'DefineQuery',
    components: {
        TwButton,
        SearchResult,
        Facet,
        TwAccordion,
        TwAccordionCard,
        DataModelTree,
        SvgImage,
        Toggle,
        AdvancedQueryBuilder,
        QuerySummary,
        DataQueryRetrievalPreview,
        Scrollbar,
        ConfirmModal,
        CombineDatasets,
        SearchResultFooter,
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        dataModels: {
            type: Array,
            required: true,
        },
        selectedDataSets: {
            type: Array,
            default: () => [],
        },
        mode: {
            type: String,
            default: null,
        },
        isAdvanceSearchEnabled: {
            type: Boolean,
            default: false,
        },
        showConfirm: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any, { emit, root }: { emit: any; root: any }) {
        const { exec, error, loading } = useAxios(true);
        const {
            filterConcepts,
            selectedDatasetAndQueryError,
            queryFullNames,
            conceptsFullNames,
            modelUidMap,
        } = useModel();

        const isFiltersSectionOpen = ref<boolean>(true);
        const isConceptsSectionOpen = ref<boolean>(false);
        const isDataQuerySectionOpen = ref<boolean>(false);
        const isDataQueryPreviewSectionOpen = ref<boolean>(false);
        const isDataQueryJoinPreviewSectionOpen = ref<boolean>(false);
        const isDataQueryCombineSectionOpen = ref<boolean>(false);
        const editTextQueryMode = ref<boolean>(false);
        const searchInProgress = ref<boolean>(false);
        const aSearchWasPerformed = ref<boolean>(false);
        const showConfirmPerformSearchModal = ref<boolean>(false);
        const showConfirmSaveAndProceed = ref<boolean>(false);
        const afterConfirmPerformSearchAction = ref<any>(null);
        const afterCancelPerformSearchAction = ref<any>(null);
        const showEmptyFacets = ref<boolean>(false);
        const searchResultKeys = ref<string[] | null>(null);

        const currentSearchText = ref<string | null>(null);
        const currentSearchField = ref<number | null>(null);

        const selectedSearchText = ref<string | null>(null);
        const selectedSearchField = ref<number | null>(null);
        const selectedConcepts: any = reactive({});
        const selectedFacets: any = reactive({
            categoriesByUid: [],
            'distribution.accessibility': [],
            'distribution.format': [],
            'distribution.language': [],
            'distribution.type': [],
            assetType: [],
            domainsByUid: [],
            fieldsByUid: [],
        });
        const selectedSortingField = ref<SortingOption>(SortingOption.all()[0]);

        const searchResults = ref<any>([]);
        const facetResults = ref<any>({});
        const sortingOptions = SortingOption.all();
        const rejectedItems = ref<number>(0);
        const dataQuery = ref<any>({
            conditions: [],
            operant: 'AND',
        });
        const currentDataset = ref<any>(null);
        const datasetsAvailableToCombine = ref<any[]>([]);
        const datasetPreviewResults = ref<any>({});
        const joinedPreviewResults = ref<any>({});
        const combinedDatasetsInformation = ref<any>({});
        const spatialIDfields = ref<any>([]);

        exec(ModelAPI.spatialConcepts()).then((res: any) => {
            spatialIDfields.value = res.data;
        });

        const availableSearchFields = computed(() =>
            [{ id: null, name: 'All', uid: null }].concat(spatialIDfields.value),
        );

        const isIndexed = (concept: any): boolean => {
            let indexed = false;
            if (concept.metadata?.index) {
                if (R.is(Boolean, concept.metadata.index) && concept.metadata.index) {
                    indexed = true;
                }
                if (
                    R.is(String, concept.metadata.index) &&
                    (concept.metadata.index === 'true' ||
                        concept.metadata.index === 'keyword' ||
                        concept.metadata.index === 'both')
                ) {
                    indexed = true;
                }
            }
            return indexed;
        };

        const selectedIndexedConcepts = computed(() => {
            let results = {};
            for (let dm = 0; dm < props.dataModels.length; dm++) {
                const dataModel = props.dataModels[dm];
                const dataModelObj = {};
                dataModelObj[dataModel.name] = dataModel;
                results = {
                    ...results,
                    ...filterConcepts(dataModelObj, (path: string, concept: any) => {
                        return (
                            selectedFacets.fieldsByUid.includes(concept.uid) &&
                            isIndexed(concept) &&
                            concept.type !== 'object'
                        );
                    }),
                };
            }
            return results;
        });

        const canDataQuerySectionBeOpen = computed(
            () => props.isAdvanceSearchEnabled && Object.keys(selectedIndexedConcepts.value).length > 0,
        );

        const dataQueryExists = computed(
            () =>
                canDataQuerySectionBeOpen &&
                !R.isNil(dataQuery.value) &&
                !R.isEmpty(dataQuery.value) &&
                S.has('conditions', dataQuery.value) &&
                dataQuery.value.conditions.length > 0,
        );

        const facetCounts = computed(() => {
            const result: any = {};
            Object.keys(facetResults.value).forEach((facetKey: string) => {
                const facet: any = facetResults.value[facetKey];
                result[facetKey] = {};
                facet.forEach((field: { value: string; selected: boolean; count: number }) => {
                    result[facetKey][field.value] = field.count;
                });
            });

            return result;
        });

        const dataModelTree = computed(() => {
            const result: any = {};
            const sortByNameCaseInsensitive = R.sortBy(R.compose(R.toLower, R.prop('name') as any));

            props.dataModels.forEach((model: any) => {
                const updatedChildren: any[] = [];
                model.children.forEach((child: any) => {
                    let children = SearchUtils.addCountToConcept(child, facetCounts.value.fieldsByUid || {});
                    children = children.reduce((accumulator: any[], c: any) => {
                        accumulator.push({ ...c, visible: c.count > 0 || showEmptyFacets.value });
                        return accumulator;
                    }, []);
                    if (children.length > 0) {
                        updatedChildren.push({
                            ...child,
                            visible: children.filter((c: { visible: boolean }) => c.visible).length > 0,
                            children: sortByNameCaseInsensitive(children),
                        });
                    }
                });

                result[model.uid] = {
                    ...model,
                    children: sortByNameCaseInsensitive(updatedChildren),
                };
            });

            return result;
        });

        const showQuerySummary = computed(() => {
            return (
                !editTextQueryMode.value &&
                !isDataQuerySectionOpen.value &&
                dataQueryExists.value &&
                Object.keys(dataModelTree).length > 0
            );
        });

        const conceptsByUid = computed(() => modelUidMap(props.dataModels));

        const processedFacets = computed(() =>
            getFacets(facetResults.value, facetCounts.value, selectedFacets, conceptsByUid.value),
        );

        const domainFooterLabel = computed(() => {
            if (selectedFacets.domainsByUid && selectedFacets.domainsByUid.length > 0 && !isConceptsSectionOpen.value) {
                return 'Show concepts';
            }
            if (selectedFacets.domainsByUid && selectedFacets.domainsByUid.length > 0 && isConceptsSectionOpen.value) {
                return 'Hide concepts';
            }
            return 'Select a domain in order to show its concepts';
        });

        const selectedSearchFreeText = computed(() => {
            if (selectedSearchField.value !== availableSearchFields.value[0].uid) {
                return null;
            }
            let cleanedSearchText: string | null = '*';

            if (!R.isNil(selectedSearchText.value) && !R.isEmpty(selectedSearchText.value)) {
                cleanedSearchText = selectedSearchText.value;
            }
            return cleanedSearchText;
        });

        const selectedSearchFieldValues = computed(() => {
            let cleanedValues: string[] = [];
            if (selectedSearchField.value === availableSearchFields.value[0].uid) {
                return [];
            }

            if (!R.isEmpty(selectedSearchField.value)) {
                if (!R.isNil(selectedSearchText.value) && !R.isEmpty(selectedSearchText.value)) {
                    cleanedValues = R.split(/\s+/, selectedSearchText.value);
                }
            }
            return cleanedValues;
        });

        const anyFacetsSelected = computed(() => {
            const facetArrays: string[] = Object.values(selectedFacets);
            const numberOfFacets: number = facetArrays.reduce((accumulator, value) => {
                return value ? accumulator + value.length : accumulator;
            }, 0);
            return numberOfFacets > 0;
        });

        const facetsFound = computed(() => {
            for (let i = 0; i < processedFacets.value.length; i++) {
                const facet = processedFacets.value[i];
                if (facet.data.length > 0) {
                    return true;
                }
            }
            return false;
        });

        const selectedConfiguration = computed(() => {
            const query = {
                text: selectedSearchFreeText.value,
                field: {
                    uid:
                        selectedSearchField.value === availableSearchFields.value[0].uid
                            ? null
                            : String(selectedSearchField.value),
                    values: selectedSearchFieldValues.value,
                },
                assetTypes: [AssetTypeId.Dataset, AssetTypeId.Result, AssetTypeId.Model],
            };
            return {
                query,
                facets: { ...selectedFacets },
                sortBy: {
                    field: selectedSortingField.value.field,
                    asc: selectedSortingField.value.asc,
                },
                dataQuery: { ...dataQuery.value },
                joinedDatasets: combinedDatasetsInformation.value,
            };
        });

        const searchResultsMap = computed(() => {
            return searchResults.value.reduce((accumulator: any, result: any) => {
                const accumulatorCopy = R.clone(accumulator);
                accumulatorCopy[result.id] = result;
                return accumulatorCopy;
            }, {});
        });

        const canProceedError = computed(() => {
            if (loading.value) return 'Please wait for loading to finish';
            if (props.selectedDataSets.length === 0) return 'You need to select at least one dataset';

            if (dataQuery.value) {
                return selectedDatasetAndQueryError(
                    props.selectedDataSets,
                    searchResultsMap.value,
                    combinedDatasetsInformation.value,
                );
            }

            return null;
        });

        const closeOpenPanes = (keepConcepts = true, keepFilters = true, clearCurrentDataset = true) => {
            if (clearCurrentDataset) currentDataset.value = null;
            isDataQuerySectionOpen.value = false;
            isDataQueryPreviewSectionOpen.value = false;
            isDataQueryJoinPreviewSectionOpen.value = false;
            isDataQueryCombineSectionOpen.value = false;
            if (!keepConcepts) {
                isConceptsSectionOpen.value = false;
            }
            if (!keepFilters) {
                isFiltersSectionOpen.value = false;
            }
        };

        const performSearch = async () => {
            closeOpenPanes();
            searchInProgress.value = true;
            const selectedConfigurationClone = R.clone(selectedConfiguration.value);
            selectedConfigurationClone.facets.fields = [];
            datasetPreviewResults.value = {};
            joinedPreviewResults.value = {};

            emit('search-started');
            await exec(SearchAPI.search(selectedConfigurationClone))
                .then((res: any) => {
                    searchResults.value = res.data?.results || [];
                    facetResults.value = res.data?.facets || {};
                    Object.keys(facetResults.value).forEach((facetKey: string) => {
                        if (!(facetKey in selectedFacets)) {
                            selectedFacets[facetKey] = [];
                        }
                    });
                    rejectedItems.value = res.data?.accessControl?.rejectedItems || 0;
                    searchResultKeys.value = [...Array(searchResults.value.length)].map(() => uuidv4());
                    emit('search-finished', selectedConfiguration.value, searchResults.value);
                })
                .catch(() => {
                    const errorMessage = error.value.response.data.message;
                    (root as any).$toastr.e(`Query could not be created: ${errorMessage}`, 'Error');
                    emit('search-finished');
                })
                .finally(() => {
                    searchInProgress.value = false;
                    aSearchWasPerformed.value = true;
                    editTextQueryMode.value = false;
                });
        };

        const checkToProceed = (proceedCallback: any, cancelCallback?: any) => {
            afterConfirmPerformSearchAction.value = proceedCallback || performSearch;
            afterCancelPerformSearchAction.value = cancelCallback || null;

            if (isDataQuerySectionOpen.value) {
                showConfirmPerformSearchModal.value = true;
            } else {
                afterConfirmPerformSearchAction.value();
                afterConfirmPerformSearchAction.value = null;
            }
        };

        const updateFacetFields = () => {
            selectedFacets.fieldsByUid = Object.values(selectedConcepts).reduce((accumulator: any, concepts: any) => {
                return accumulator.concat(concepts);
            }, []);
        };

        function isConditionSatisfied(dataset: any, concept: any) {
            return dataset.dataQueries.filter(
                (condition: any) => condition.conditionUid === concept.conditionUid && condition.satisfied,
            ).length;
        }

        function removeUnsatisfiedConditions(dataset: any, query: any) {
            const tempQuery = R.clone(query);
            if (tempQuery.conditions) {
                tempQuery.conditions = tempQuery.conditions
                    .map((condition: any) => removeUnsatisfiedConditions(dataset, condition))
                    .filter((element: any) => element !== null);

                return tempQuery.conditions.length > 0 ? tempQuery : null;
            }
            if (tempQuery.conditionUid && isConditionSatisfied(dataset, tempQuery)) {
                return tempQuery;
            }
            return null;
        }

        const previewData = (dataset: any, asJoin = false) => {
            closeOpenPanes(false, false, false);

            let modifiedQuery = props.configuration.dataQuery;
            if (!asJoin) {
                modifiedQuery = removeUnsatisfiedConditions(dataset, props.configuration.dataQuery);
            }

            const allFields = facetResults.value.fields.map((field: any) => field.value);
            const selectedFieldsPaths = props.configuration.facets.fieldsByUid.reduce((acc: string[], uid: string) => {
                acc.push(`${conceptsByUid.value[uid].parent}.${conceptsByUid.value[uid].name}`);
                return acc;
            }, []);

            const payload = {
                datasetId: dataset.id,
                datasetName: dataset.name,
                dataQuery: queryFullNames(modifiedQuery, allFields),
                fields: conceptsFullNames(allFields, selectedFieldsPaths),
                join: asJoin ? combinedDatasetsInformation.value[dataset.id] : null,
            };
            exec(SearchAPI.preview(dataset.id, payload))
                .then((res: any) => {
                    if (asJoin) {
                        joinedPreviewResults.value[dataset.id] = res.data;
                        isDataQueryJoinPreviewSectionOpen.value = true;
                    } else {
                        datasetPreviewResults.value[dataset.id] = res.data;
                        isDataQueryPreviewSectionOpen.value = true;
                    }
                    (root as any).$toastr.s('Query successfully executed!', 'Success');
                })
                .catch(() => {
                    const errorMessage = error.value.response.data.message;
                    (root as any).$toastr.e(errorMessage, 'Error executing query');
                });
        };

        const conceptSelectionsChangedEvent = (selections: string[], domain: string) => {
            const oldConcepts = R.clone(selectedConcepts[domain]);
            selectedConcepts[domain] = selections;
            checkToProceed(
                () => {
                    selectedConcepts[domain] = selections;
                    updateFacetFields();
                    performSearch();
                },
                () => {
                    selectedConcepts[domain] = oldConcepts;
                },
            );
        };

        const searchFieldChangedEvent = () => {
            currentSearchText.value = null;
        };

        const clearSelectedFacetsEvent = () => {
            checkToProceed(() => {
                Object.keys(selectedFacets).forEach((facetKey: string) => {
                    selectedFacets[facetKey] = [];
                });
                isConceptsSectionOpen.value = false;
                performSearch();
            });
        };

        const searchEnterPressedEvent = () => {
            checkToProceed(() => {
                selectedSearchText.value = currentSearchText.value;
                selectedSearchField.value = currentSearchField.value;
                performSearch();
            });
        };

        const searchInputChangedEvent = (e: any) => {
            if (!e.currentTarget.value || R.trim(e.currentTarget.value) === '') {
                searchEnterPressedEvent();
            }
        };

        const addToSearchResultsEvent = (id: number, type: string, result: any) => {
            emit('add-to-search-results', id, type, result);
        };

        const removeFromSearchResultsEvent = (id: number, type: string, result: any) => {
            emit('remove-from-search-results', id, type, result);
        };

        const combineDatasetEvent = (dataset: any) => {
            if (isDataQueryCombineSectionOpen.value && currentDataset.value.id === dataset.id) {
                isDataQueryCombineSectionOpen.value = false;
                currentDataset.value = null;
            } else {
                closeOpenPanes(false, false);
                currentDataset.value = dataset;

                datasetsAvailableToCombine.value = [];
                dataset.dataQueries.forEach((dataQueryResult: { canBeSatisfiedBy: { id: number; name: string }[] }) => {
                    if (dataQueryResult.canBeSatisfiedBy.length > 0) {
                        dataQueryResult.canBeSatisfiedBy.forEach((satisfyingDataset: { id: number; name: string }) => {
                            datasetsAvailableToCombine.value.push(searchResultsMap.value[satisfyingDataset.id]);
                        });
                    }
                });

                isDataQueryCombineSectionOpen.value = true;
            }
        };

        const facetsChangedEvent = (selections: string[], facetKey: string) => {
            checkToProceed(() => {
                selectedFacets[facetKey].forEach((selection: string, index: number) => {
                    if (!selections.includes(selection)) {
                        selectedFacets[facetKey].splice(index, 1);

                        if (facetKey === 'domainsByUid') {
                            if (selection in selectedConcepts) {
                                selectedConcepts[selection] = [];
                            }
                            updateFacetFields();
                        }
                    }
                });
                selections.forEach((selection: string) => {
                    if (!R.isNil(selection) && !selectedFacets[facetKey].includes(selection)) {
                        selectedFacets[facetKey].push(selection);
                    }
                });
                if (selectedFacets.domainsByUid && selectedFacets.domainsByUid.length === 0) {
                    isConceptsSectionOpen.value = false;
                }
                performSearch();
            });
        };

        const cancelEditSearchTextEvent = () => {
            editTextQueryMode.value = false;
            currentSearchText.value = selectedSearchText.value;
            currentSearchField.value = selectedSearchField.value;
        };

        const combineDatasetAndPreviewEvent = (combinationData: {
            localField: string;
            foreignField: string;
            datasetId: number;
            datasetName: string;
        }) => {
            if (currentDataset.value) {
                isDataQueryCombineSectionOpen.value = false;
                datasetsAvailableToCombine.value = [];
                combinedDatasetsInformation.value[currentDataset.value.id] = combinationData;

                previewData(currentDataset.value, true);
            }
        };

        const previewDataEvent = (dataset: any) => {
            if (currentDataset.value && isDataQueryPreviewSectionOpen.value && dataset.id === currentDataset.value.id) {
                isDataQueryPreviewSectionOpen.value = false;
                currentDataset.value = null;
            } else {
                currentDataset.value = dataset;
                if (S.has(dataset.id, datasetPreviewResults)) {
                    isDataQueryPreviewSectionOpen.value = true;
                } else {
                    previewData(dataset);
                }
            }
        };

        const previewJoinDataEvent = (dataset: any) => {
            if (
                currentDataset.value &&
                isDataQueryJoinPreviewSectionOpen.value &&
                dataset.id === currentDataset.value.id
            ) {
                isDataQueryJoinPreviewSectionOpen.value = false;
                currentDataset.value = null;
            } else {
                currentDataset.value = dataset;
                if (S.has(dataset.id, joinedPreviewResults)) {
                    isDataQueryJoinPreviewSectionOpen.value = true;
                } else {
                    previewData(dataset, true);
                }
            }
        };

        const cancelCombineDatasetEvent = () => {
            isDataQueryCombineSectionOpen.value = false;
            currentDataset.value = null;
            datasetsAvailableToCombine.value = [];
        };

        const clearDatasetCombinationEvent = () => {
            isDataQueryCombineSectionOpen.value = false;
            const clonedCombinedDatasetsInformation = R.clone(combinedDatasetsInformation.value);
            delete clonedCombinedDatasetsInformation[currentDataset.value.id];
            combinedDatasetsInformation.value = clonedCombinedDatasetsInformation;
            currentDataset.value = null;
        };

        const onConfigurationChange = (config: any) => {
            currentSearchText.value = SearchUtils.initialTextCalculator(config.query);
            currentSearchField.value = config.query?.field?.fieldId || availableSearchFields.value[0].uid;
            selectedSearchText.value = currentSearchText.value;
            selectedSearchField.value = currentSearchField.value;
            selectedFacets.categoriesByUid = config.facets.categoriesByUid || [];
            selectedFacets['distribution.accessibility'] = config.facets['distribution.accessibility'] || [];
            selectedFacets['distribution.format'] = config.facets['distribution.format'] || [];
            selectedFacets['distribution.language'] = config.facets['distribution.language'] || [];
            selectedFacets['distribution.type'] = config.facets['distribution.type'] || [];
            selectedFacets['assetType'] = config.facets['assetType'] || [];
            selectedFacets.domainsByUid = config.facets.domainsByUid || [];
            selectedFacets.fields = config.facets.fields || [];
            selectedFacets.fieldsByUid = config.facets.fieldsByUid || [];
            selectedSortingField.value =
                SortingOption.find(config.sortBy.field, config.sortBy.asc) || SortingOption.all()[0];
            for (let d = 0; d < selectedFacets.domainsByUid.length; d++) {
                selectedConcepts[selectedFacets.domainsByUid[d]] = selectedFacets.fieldsByUid;
            }
            if (props.isAdvanceSearchEnabled) {
                dataQuery.value = config.dataQuery;
            } else {
                dataQuery.value = {
                    conditions: [],
                    operant: 'AND',
                };
            }
            combinedDatasetsInformation.value = config.joinedDatasets;

            performSearch();
        };

        watch(
            () => props.configuration,
            (config: any) => onConfigurationChange(config),
        );

        onConfigurationChange(props.configuration);

        const toggleDataQuerySection = () => {
            if (canDataQuerySectionBeOpen.value) {
                isDataQuerySectionOpen.value = !isDataQuerySectionOpen.value;
            }
            isDataQueryPreviewSectionOpen.value = false;
        };

        return {
            emit,
            rejectedItems,
            isIndexed,
            showEmptyFacets,
            dataQueryExists,
            searchInProgress,
            searchResultKeys,
            showQuerySummary,
            editTextQueryMode,
            aSearchWasPerformed,
            isFiltersSectionOpen,
            isConceptsSectionOpen,
            isDataQuerySectionOpen,
            canDataQuerySectionBeOpen,
            showConfirmPerformSearchModal,
            isDataQueryPreviewSectionOpen,
            isDataQueryCombineSectionOpen,
            isDataQueryJoinPreviewSectionOpen,

            selectedFacets,
            selectedConcepts,
            currentSearchText,
            currentSearchField,
            selectedSortingField,
            datasetPreviewResults,
            selectedIndexedConcepts,

            dataQuery,
            previewData,
            facetsFound,
            searchResults,
            dataModelTree,
            sortingOptions,
            conceptsByUid,
            currentDataset,
            processedFacets,
            canProceedError,
            domainFooterLabel,
            anyFacetsSelected,
            joinedPreviewResults,
            availableSearchFields,
            datasetsAvailableToCombine,
            combinedDatasetsInformation,

            performSearch,
            closeOpenPanes,
            afterConfirmPerformSearchAction,
            afterCancelPerformSearchAction,

            uuidv4,
            previewDataEvent,
            facetsChangedEvent,
            combineDatasetEvent,
            previewJoinDataEvent,
            toggleDataQuerySection,
            searchFieldChangedEvent,
            searchEnterPressedEvent,
            addToSearchResultsEvent,
            searchInputChangedEvent,
            clearSelectedFacetsEvent,
            cancelCombineDatasetEvent,
            cancelEditSearchTextEvent,
            removeFromSearchResultsEvent,
            clearDatasetCombinationEvent,
            conceptSelectionsChangedEvent,
            combineDatasetAndPreviewEvent,
            showConfirmSaveAndProceed,
        };
    },
});










































































































































































import { useAxios, useErrors, useQuery, useResult } from '@/app/composable';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'; // eslint-disable-line

import { FormBlock, FormModal, TwButton, WizardTabs } from '@/app/components';
import { maxLengthValidator } from '@/app/validators';
import { AssetsAPI } from '@/modules/asset/api';
import { AssetTypeId } from '@/modules/asset/constants';
import { ModelAPI, QueryAPI } from '@/modules/search/api';
import ConfigureDatasetQuery from './configure-query/ConfigureDatasetQuery.vue';
import ConfigureFileQuery from './configure-query/ConfigureFileQuery.vue';
import ConfigureStreamingQuery from './configure-query/ConfigureStreamingQuery.vue';

import DatasetQueryResultsAcquisition from './query-results/DatasetQueryResultsAcquisition.vue';
import DatasetQueryRetrievalPreview from './query-results/DatasetQueryRetrievalPreview.vue';
import FileQueryResults from './query-results/FileQueryResults.vue';
import StreamingQueryResults from './query-results/StreamingQueryResults.vue';

import DefineQuery from './DefineQuery.vue';

import { SearchResultType } from '../constants';
import { SearchUtils } from '../utils';

import { useModel } from '../composable';
import GET_QUERY from '../graphql/query.graphql';
import Queries from './view-queries/Queries.vue';
import { S } from '@/app/utilities';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('max', maxLengthValidator);

export default defineComponent({
    name: 'PerformSearch',
    metaInfo() {
        return { title: `Search${(this as any).query ? `: ${(this as any).query.title}` : ''}` };
    },
    components: {
        WizardTabs,
        TwButton,
        ConfigureStreamingQuery,
        StreamingQueryResults,
        DefineQuery,
        FormBlock,
        FormModal,
        ValidationProvider,
        ConfigureDatasetQuery,
        ConfigureFileQuery,
        FileQueryResults,
        DatasetQueryRetrievalPreview,
        DatasetQueryResultsAcquisition,
        OrbitSpinner,
        Queries,
    },
    props: {
        id: {
            type: String,
            required: false,
        },
    },
    setup(props, { root }) {
        const { loading, exec, error } = useAxios(true);
        const { modelUidMap } = useModel();

        const isFirstTime = ref<boolean>(true);
        const dataModels = ref<any>([]);
        const currentMode = ref<SearchResultType | null>(null);
        const searchInProgress = ref<boolean>(false);

        exec(ModelAPI.reduced()).then((res: any) => {
            dataModels.value = res.data;
        });

        // result selections
        const dataSets: any = reactive({});

        const isAdvanceSearchEnabled = computed(() => false);

        // tab information
        const tabs = computed(() => {
            const steps = [
                { title: 'Search Query Definition' },
                { title: 'Search Results Configuration' },
                { title: 'Test Results Acquisition' },
                { title: 'Results Acquisition Information' },
            ];
            if (currentMode.value === SearchResultType.OTHER) {
                steps[2].title = 'Results Acquisition';
            }
            if (currentMode.value === SearchResultType.API) {
                return steps;
            }
            return steps.slice(0, -1);
        });
        const activeTab = ref(0);

        // save query modal information
        const showSaveModal = ref<boolean>(false);
        const modalQueryTitle = ref<string | null>();
        const modalQueryDescription = ref<string | null>();
        const editQueryId = ref<string | null>(null);
        const editQueryConfiguration = ref<any>(null);

        // loading existing query information
        const isQueryLoaded = ref<boolean>(R.isNil(props.id));
        const isSaved = ref<boolean>(false);
        const showQueryLoaded = ref<boolean>(false);
        const showConfirm = ref<boolean>(false);

        const showQueries = ref<boolean>(false);
        const { checkGQLAuthentication } = useErrors();
        const response = useQuery(GET_QUERY, {}, { fetchPolicy: 'no-cache' });
        response.onError(checkGQLAuthentication);
        const queries = useResult(response.result, null, (data: any) => data.queries);

        const editQuery = (query: any) => {
            editQueryId.value = query.id;
            editQueryConfiguration.value = query.configuration;
            modalQueryTitle.value = query.title;
            modalQueryDescription.value = query.description;
            showSaveModal.value = true;
        };

        // a blank query object to be filled out
        const emptyQuery = {
            title: null,
            description: null,
            configuration: {
                query: {},
                sortBy: {},
                facets: {},
                dataQuery: {
                    conditions: [],
                    operant: 'AND',
                },
                joinedDatasets: {},
            },
            result: {
                datasets: [],
                params: {},
                download: 'direct',
                kafkaConnectionDetails: {},
                binaryConceptSelected: false,
            },
        };
        const query = ref<any>(R.clone(emptyQuery));

        const configuration = ref<{ query: any; sortBy: any; facets: any; dataQuery: any; joinedDatasets: any }>(
            query.value.configuration,
        );
        const result = ref<any>(query.value.result);

        const selectedDatasetResultIds = computed(() => {
            const datasetIds: number[] = [];
            result.value.datasets.forEach((dataset: { id: any }) => {
                let { id } = dataset;
                if (R.is(String, id)) {
                    id = parseInt(dataset.id, 10);
                }
                datasetIds.push(id);
            });
            return datasetIds;
        });

        const updateDatasets = (newResult: any) => {
            dataSets[newResult.id] = {
                ...newResult,
                selectedAction: newResult.id in result.value ? result.value[newResult.id] : null,
            };
            const mapper: any = {};
            const conceptsByUid = modelUidMap(dataModels.value);
            exec(AssetsAPI.getAsset(newResult.id)).then((res: any) => {
                const asset = res.data;
                const { schema } = asset.structure;
                if (schema !== null) {
                    const selection = SearchUtils.createFieldPathsFromSchema(schema, asset.assetTypeId);
                    Object.keys(selection).forEach((fieldPath: string) => {
                        const fieldPathParts = fieldPath.split('.');

                        let fieldSoFar = '';
                        fieldPathParts.reduce((parent: any, key: string, i: number) => {
                            fieldSoFar += parent.name;
                            const uniqueKey = fieldSoFar + key;
                            if (!mapper[uniqueKey]) {
                                let node: any;
                                let field;
                                if (asset.assetTypeId === AssetTypeId.Dataset) {
                                    const fieldId = selection[fieldPath];
                                    field = conceptsByUid[fieldId];
                                    field.index = [true, 'true', 'keyword', 'both'].includes(field.metadata.index);
                                } else if (asset.assetTypeId === AssetTypeId.Result) {
                                    field = selection[fieldPath];
                                }

                                if (i === fieldPathParts.length - 1) {
                                    node = {
                                        key,
                                        name: key,
                                        description: field.description || key,
                                        type: field.type,
                                        multiple: false,
                                        indexed: field.index,
                                    };
                                } else {
                                    node = {
                                        key,
                                        name: key,
                                        description: key,
                                        type: 'object',
                                        children: [],
                                    };
                                }

                                // eslint-disable-next-line no-param-reassign
                                parent.children = parent.children || [];
                                parent.children.push(node);
                                mapper[uniqueKey] = node;
                            }
                            return mapper[uniqueKey];
                        }, dataSets[newResult.id]);
                    });
                }
            });
        };

        const clearSelectedDatasets = () => {
            SearchUtils.clearObject(dataSets);
        };

        const step2 = computed(() => {
            switch (currentMode.value) {
                case SearchResultType.API:
                    return ConfigureDatasetQuery;
                case SearchResultType.KAFKA:
                    return ConfigureStreamingQuery;
                case SearchResultType.OTHER:
                    return ConfigureFileQuery;
                default:
                    return null;
            }
        });

        const step3 = computed(() => {
            switch (currentMode.value) {
                case SearchResultType.API:
                    return DatasetQueryRetrievalPreview;
                case SearchResultType.KAFKA:
                    return StreamingQueryResults;
                case SearchResultType.OTHER:
                    return FileQueryResults;
                default:
                    return null;
            }
        });

        const step4 = computed(() => {
            switch (currentMode.value) {
                case SearchResultType.API:
                    return DatasetQueryResultsAcquisition;
                default:
                    return null;
            }
        });

        // Events
        const save = (): Promise<void> => {
            return new Promise((resolve, reject) => {
                const newQuery: any = {
                    ...query.value,
                    title: modalQueryTitle.value,
                    description: modalQueryDescription.value,
                    configuration: {
                        query: configuration.value.query,
                        sortBy: configuration.value.sortBy,
                        facets: configuration.value.facets,
                        dataQuery: configuration.value.dataQuery,
                        joinedDatasets: configuration.value.joinedDatasets,
                    },
                    result: {
                        datasets: result.value.datasets,
                        params: result.value.params,
                        download: result.value.download,
                        kafkaConnectionDetails: result.value.kafkaConnectionDetails,
                        binaryConceptSelected: result.value.binaryConceptSelected,
                    },
                };

                if (!props.id) {
                    exec(QueryAPI.create(newQuery))
                        .then((res: any) => {
                            query.value = res.data;
                            (root as any).$toastr.s('Query successfully created!', 'Success');
                            resolve();
                            root.$router.push({ name: 'search-specific-query', params: { id: res.data.id } });
                            isSaved.value = true;
                            queries.value.splice(queries.value.length, 0, res.data);
                        })
                        .catch(() => {
                            const errorMessage = error.value.response.data.message;
                            (root as any).$toastr.e(`Query could not be created: ${errorMessage}`, 'Error');
                            reject(errorMessage);
                        });
                } else {
                    exec(QueryAPI.update(props.id, newQuery))
                        .then((res: any) => {
                            query.value = res.data;
                            const index = queries.value.findIndex((q: any) => q.id === res.data.id);
                            queries.value.splice(index, 1, res.data);
                            resolve();
                        })
                        .catch(() => {
                            const errorMessage = error.value.response.data.message;
                            (root as any).$toastr.e(`Query could not be saved: ${errorMessage}`, 'Error');
                            reject(errorMessage);
                        });
                }
            });
        };

        let afterSaveModalAction: any = null;

        const saveButton = () => {
            if (props.id) {
                save().then(() => {
                    (root as any).$toastr.s('Query successfully saved!', 'Success');
                });
            } else {
                modalQueryTitle.value = null;
                modalQueryDescription.value = null;
                afterSaveModalAction = null;
                showSaveModal.value = true;
            }
        };

        // function to be executed after save modal

        const saveModal = () => {
            showSaveModal.value = false;
            if (!editQueryId.value) {
                save().then(() => {
                    if (afterSaveModalAction) {
                        afterSaveModalAction();
                    }
                });
            } else {
                const updatedQuery: any = {
                    title: modalQueryTitle.value,
                    description: modalQueryDescription.value,
                    configuration: editQueryConfiguration.value,
                };
                exec(QueryAPI.update(editQueryId.value, updatedQuery))
                    .then((res: any) => {
                        const index = queries.value.findIndex((q: any) => q.id === res.data.id);
                        queries.value.splice(index, 1, res.data);
                        (root as any).$toastr.s('Query successfully updated!', 'Success');
                    })
                    .catch(() => {
                        const errorMessage = error.value.response.data.message;
                        (root as any).$toastr.e(`Query could not be updated: ${errorMessage}`, 'Error');
                    });
                editQueryId.value = null;
                editQueryConfiguration.value = null;
            }
        };

        const next = async () => {
            if (props.id) {
                save().then(() => {
                    isFirstTime.value = true;
                    activeTab.value += 1;
                });
            } else {
                modalQueryTitle.value = null;
                modalQueryDescription.value = null;
                showSaveModal.value = true;
                afterSaveModalAction = () => {
                    isFirstTime.value = true;
                    activeTab.value += 1;
                };
            }
        };
        const previous = () => {
            activeTab.value -= 1;
        };

        const addToSearchResults = (
            resultId: number,
            type: string,
            res: { id: number; name: string; schemaSelection: any },
        ) => {
            result.value.datasets.push({
                id: res.id,
                fields: [],
                selectedAction: type,
            });
            currentMode.value = SearchResultType.findByKey(type);
            updateDatasets(res);
            showConfirm.value = false;
        };

        const removeFromSearchResults = (resultId: number) => {
            result.value.datasets = result.value.datasets.filter((dataset: { id: any }) => {
                let { id } = dataset;
                if (R.is(String, id)) {
                    id = parseInt(id, 10);
                }
                return id !== resultId;
            });

            delete dataSets[resultId];
            if (Object.keys(result.value.datasets).length === 0) {
                currentMode.value = null;
            }

            // Consolidating all the valid fields from the now available search results
            // Removing any parameters from that belong to removed results
            const validFields = result.value.datasets.reduce((accumulator: string[], dataset: { fields: string[] }) => {
                return accumulator.concat(dataset.fields);
            }, []);
            result.value.params = Object.keys(result.value.params).reduce((accumulator: any, paramKey: any) => {
                const updatedParams = { ...accumulator };
                if (validFields.includes(paramKey)) {
                    updatedParams[paramKey] = result.value.params[paramKey];
                }
                return updatedParams;
            }, {});
        };

        const searchStarted = () => {
            searchInProgress.value = true;
        };

        const searchFinished = (newConfiguration: any, searchResults: any[]) => {
            searchInProgress.value = false;
            if (newConfiguration) {
                configuration.value.query = newConfiguration.query;
                configuration.value.sortBy = newConfiguration.sortBy;
                configuration.value.facets = newConfiguration.facets;
                configuration.value.dataQuery = newConfiguration.dataQuery;
                configuration.value.joinedDatasets = newConfiguration.joinedDatasets;
            }
            if (searchResults) {
                const datasets = result.value.datasets.filter((dataset: any) =>
                    searchResults.map((sr: any) => sr.id).includes(parseInt(dataset.id, 10)),
                );
                if (showQueryLoaded.value) {
                    if (datasets.length !== result.value.datasets.length) {
                        showConfirm.value = true;
                        (root as any).$toastr.Add({
                            msg: `Search query with title "${S.sanitizeHtml(
                                query.value.title,
                            )}" has been loaded. Please note that one or more datasets have been discarded from the original query, due to changes in the applicable access policies.`,
                            title: 'Warning',
                            type: 'warning',
                            timeout: 10000,
                        });
                    } else {
                        showConfirm.value = false;
                        (root as any).$toastr.s(
                            `Search query with title "${S.sanitizeHtml(query.value.title)}" has been loaded.`,
                            'Success',
                        );
                    }
                    showQueryLoaded.value = false;
                }
                result.value.datasets = datasets;
                const searchResultsObj = searchResults.reduce((accumulator: any, searchResult: { id: number }) => {
                    return {
                        ...accumulator,
                        [searchResult.id]: searchResult,
                    };
                }, {});

                result.value.datasets.forEach((selectedDataset: { id: any; selectedAction: string }) => {
                    let { id } = selectedDataset;
                    if (R.is(String, id)) {
                        id = parseInt(selectedDataset.id, 10);
                    }

                    if (id in searchResultsObj) {
                        updateDatasets(searchResultsObj[id]);
                        currentMode.value = SearchResultType.findByKey(selectedDataset.selectedAction);
                    }
                });
            }
            if (isFirstTime.value) {
                isFirstTime.value = false;
            }
        };

        watch(
            () => props.id,
            (id) => {
                // Load query (if an id is provided)
                if (id) {
                    showQueries.value = false;
                    exec(QueryAPI.get(id))
                        .then((res: any) => {
                            query.value = res.data;
                            modalQueryTitle.value = query.value.title;
                            modalQueryDescription.value = query.value.description;
                            result.value = query.value.result;
                            configuration.value = query.value.configuration;
                            isSaved.value = true;
                            if (activeTab.value === 0) {
                                clearSelectedDatasets();
                                isFirstTime.value = true;
                                showQueryLoaded.value = true;
                            }
                        })
                        .catch(() => {
                            const errorMessage = error.value.response.data.message;
                            (root as any).$toastr.e(`Error finding query: ${errorMessage}`, 'Error');
                            root.$router.push({ name: 'search' });
                        })
                        .then(() => {
                            isQueryLoaded.value = true;
                        });
                } else {
                    isQueryLoaded.value = true;
                }
            },
            {
                immediate: true,
            },
        );

        watch(
            () => root.$route.fullPath,
            () => {
                if (root.$route.name === 'search') {
                    query.value = R.clone(emptyQuery);
                    result.value = query.value.result;
                    configuration.value = query.value.configuration;
                    isSaved.value = false;
                    activeTab.value = 0;
                    currentMode.value = null;
                    isQueryLoaded.value = true;
                    clearSelectedDatasets();
                }
            },
        );

        return {
            query,
            searchInProgress,
            dataModels,
            tabs,
            activeTab,
            dataSets,
            selectedDatasetResultIds,
            result,
            configuration,
            isSaved,
            modalQueryTitle,
            modalQueryDescription,
            loading,
            showSaveModal,
            isQueryLoaded,
            previous,
            next,
            saveButton,
            saveModal,
            addToSearchResults,
            removeFromSearchResults,
            searchStarted,
            searchFinished,
            currentMode,
            step2,
            step3,
            step4,
            queries,
            showQueries,
            editQuery,
            isAdvanceSearchEnabled,
            showConfirm,
        };
    },
});

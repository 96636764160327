


































































import * as R from 'ramda';
import { defineComponent, ref } from '@vue/composition-api';
import { useAxios } from '@/app/composable';
import { OrbitSpinner } from 'epic-spinners';
import { TwButton, SvgImage, JsonEditor } from '@/app/components';

const sampleValues: any = {
    string: 'ENTER_VALUE',
    integer: 123,
    double: 123.4,
    datetime: '2020-02-20 00:00:00',
};

const sampleRanges: any = {
    integer: { min: 123, max: 456 },
    double: { min: 123.4, max: 456.7 },
    datetime: { min: '2020-02-20 00:00:00', max: '2020-02-22 23:59:59' },
};

export default defineComponent({
    name: 'RetrievalPreview',
    props: {
        id: {
            type: String,
            required: false,
        },
        configuration: {
            type: Object,
            required: true,
        },
    },
    components: { JsonEditor, TwButton, SvgImage, OrbitSpinner },
    setup(props, { emit }) {
        const url = `/api/search/test-query/${props.id}`;
        const query = {};
        const errorMessage = ref<string | null>(null);

        R.forEachObjIndexed((value, key) => {
            if (value.filterType === 'value') {
                Reflect.set(query, key, sampleValues[value.fieldType]);
            } else if (value.filterType === 'range') {
                Reflect.set(query, key, sampleRanges[value.fieldType]);
            }
        }, props.configuration.params);

        const queryString = ref(JSON.stringify(query, null, '\t'));

        const { exec, loading, error } = useAxios(true);

        const response = ref<string | null>(null);
        const executeQuery = () => {
            response.value = null;
            errorMessage.value = null;

            exec({ method: 'POST', url, data: JSON.parse(queryString.value) })
                .then((res: any) => (response.value = JSON.stringify(res.data, null, '\t')))
                .catch(() => (errorMessage.value = error.value.response.data.message));
        };

        return { response, errorMessage, queryString, executeQuery, emit, loading };
    },
});

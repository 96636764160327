




























import { defineComponent, computed } from '@vue/composition-api';
import { extend } from 'vee-validate';
import { S } from '@/app/utilities';
import ConditionViewer from './ConditionViewer.vue';
import { requiredValidator } from '@/app/validators';

extend('required', requiredValidator);

export default defineComponent({
    name: 'DataQuerySummary',
    components: { ConditionViewer },
    props: {
        concepts: {
            type: Object,
            required: true,
        },
        dataQuery: {
            type: Object,
            required: true,
        },
        isRoot: {
            type: Boolean,
            default: true,
        },
    },
    setup: (props: any) => {
        const concept = computed(() => {
            if (S.has('concept', props.dataQuery) && S.has(props.dataQuery.concept, props.concepts)) {
                return props.concepts[props.dataQuery.concept];
            }
            return null;
        });
        const operant = computed(() => {
            return props.dataQuery.operant.replaceAll('_', ' ');
        });

        return { operant, concept };
    },
});
